<template>
  <main v-if="product" class="content container">
    <div class="row">
      <div class="col-sm-12">
        <div class="mt-3">
          <button type="button" class="btn btn-lg btn-outline-primary" @click="back">
            Zurück
          </button>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-5 border-right">
        <img :src="$filters.image(product.image)" class="img-fluid">
      </div>
      <div class="col-sm-7">
        <h3 class="mb-2 title">{{ product.name }}</h3>
         <p class="price-detail-wrap">
          <span v-if="product.discount > 0" class="price h3 text-danger">
            <span class="badge bg-danger">-{{ product.discount }}%</span>
            <span class="option__price option__price--discount">{{ $filters.currency(discount) }}</span>
            <span class="option__price option__price--old">{{ $filters.currency(price) }}</span>
          </span>
          <span v-else class="h3">
            <span class="option__price option__price--discount">{{ $filters.currency(price) }}</span>
          </span>
          </p>
          <dl v-if="product.print_description" class="item-property">
            <dt>Beschreibung:</dt>
            <dd><p v-html="product.print_description" /></dd>
          </dl>
          <hr>
          <div class="row">
            <div class="col-sm-6">
              <dl class="param param-inline">
                <dt>Anzahl: </dt>
                <dd>
                  <div class="input-group">
                    <input v-model.number="qty" type="number" class="form-control form-control-sm" min="1" max="50">
                    <button type="button" class="btn btn-lg btn-outline-primary text-uppercase" @click="addToCart(product.id)">In den Warenkorb</button>
                  </div>
                </dd>
              </dl>
            </div>
          </div>
      </div>
    </div>
  </main>
</template>
<script>
import { computed, ref } from '@vue/reactivity'
import { onMounted, watchEffect } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { useMeta } from 'vue-meta'
import axios from 'axios'
import { useRoute, useRouter } from 'vue-router'
export default {
  setup () {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    const qty = ref(1)
    const product = ref(null)
    // page meta
    const { meta } = useMeta({
      title: 'Product'
    })

    // computed
    const price = computed(() => {
      return qty.value * product.value.price
    })
    const discount = computed(() => {
      const discount = Number.parseFloat(product.value.discount) / 100 * price.value
      let totalDiscount = 0
      totalDiscount = price.value - discount
      return totalDiscount
    })
    async function getProduct () {
      const apiKey = btoa(process.env.VUE_APP_API_KEY)
      const apiUrl = process.env.VUE_APP_API_URL
      const slug = route.params.product
      await axios.post(apiUrl + 'product/' + slug, {
        re: apiKey
      }).then((resp) => {
        product.value = resp.data
      })
    }
    function addToCart (id) {
      const quantity = qty.value
      const data = {
        id: product.value.id,
        images: [{ path: product.value.image }],
        main_image: product.value.image,
        multiple: false,
        name: product.value.name,
        discount: product.value.discount,
        price: {
          discounted: this.discount * quantity,
          total: product.value.price * quantity,
          old_price: product.value.price,
          delivery: 0
        },
        description: product.value.print_description,
        quantity: quantity
      }
      if (product.value) {
        store.dispatch('checkout/addProduct', data)
        router.push({ name: 'cart' })
      }
    }
    function back () {
      router.go(-1)
    }
    onMounted(() => {
      getProduct()
      store.dispatch('checkout/setDeliveryCountries')
    })
    watchEffect(() => {
      if (product.value) {
        meta.title = product.value.name + '-Loewe-zaun.de'
      }
    })
    return {
      qty,
      price,
      discount,
      product,
      back,
      addToCart
    }
  }
}
</script>
